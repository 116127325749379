<template>
  <v-dialog :value="show" max-width="500px" @input="close()">
    <v-card class="modal-body">
      <v-layout align-center justify-end>
        <div class="btn-close">
          <v-btn icon dark class="ma-0" @click.native="close">
            <v-icon>close</v-icon>
          </v-btn>
        </div>
      </v-layout>

      <v-card-text>
        <span class="modal-text break">
          Are you sure you want to delete this post forever? You will not have possibility to restore it!
        </span>
      </v-card-text>

      <v-card-actions class="px-3 pt-3 pb-4">
        <v-spacer />
        <v-btn color="error" @click.native="close">
          No
        </v-btn>
        <v-btn color="success" :loading="isLoading" @click.native="accept()">
          Yes, I'm sure!
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { deleteForeverPost, deleteParsedForeverPost } from "@/api/post"

export default {
  name: "DeleteCampaignModal",
  props: {
    value: Boolean,
    // item: {
    //   type: [Object],
    //   default: () => {}
    // },
    postId: {
      type: [String, Number],
      default: undefined
    },
    postType: {
      type: String,
      default: undefined
    },
    refresh: {
      type: [Function],
      default: () => {}
    }
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    }
  },
  methods: {
    close() {
      this.show = false
    },
    postDeletedSuccess () {
      this.$emit('accept')
      this.show = false
    },
    async deletePost(params) {
      params.reason_id = 1
      await deleteForeverPost(params)
          .then(() => {
            this.postDeletedSuccess()
          })
          .catch(this.$notificationHandleResponseError)
          .finally(() => (this.isLoading = false))
    },
    async deleteParsedPost(params){
      params.resource = this.postType
      await deleteParsedForeverPost(params)
          .then(() => {
            this.postDeletedSuccess()
          })
          .catch(this.$notificationHandleResponseError)
          .finally(() => (this.isLoading = false))
    },
    accept() {
      this.isLoading = true
      const params = {
        post_id: this.postId
      }
      !this.postType ? this.deletePost(params) : this.deleteParsedPost(params)
    }
  }
}
</script>
<style scoped>
.modal-text{
  font-size: 18px;
}
</style>
